import { AgGrid, RoundedIconTextButton, Typography } from '@cfra-nextgen-frontend/shared';
import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGridSelectedRowsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/SmallGridTheme.scss';
import { setCustomFlexWidths } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { WatchListIcon } from '@cfra-nextgen-frontend/shared/src/components/Screener/Watchlists/WatchListIcon';
import {
    WatchListButtonTheme,
    WatchListColumn,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/Watchlists/WatchListUtils';
import { FiltersModalContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { SaveScreenContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { WatchListContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { ScreenerEtfData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { watchListColumnWidth } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { Box, styled, useMediaQuery } from '@mui/material';
import { ITooltipParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { SingleThemeData } from 'features/cfraInsights/types/thematic';
import { getScreenerData } from 'features/etfScreener/api/screener';
import { WatchListModal } from '@cfra-nextgen-frontend/shared/src/components/Watchlist/WatchListModals';
import { SnackbarProvider } from 'notistack';
import React, { useContext, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { PageNames } from 'utils/enums';
import './AgGridInsightSpecificStyles.scss';
import { CommonMediumLabelStyle, Image, LabelStyle, Description as SharedDescription } from './shared';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';

export const ETFsCount = styled(Typography)(({ theme }) => ({
    ...CommonMediumLabelStyle,
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: '16px',
    lineHeight: 1.66,
    letterSpacing: '0.4px',
}));

export function WatchListModalOpenButton({ onClickCallback }: { onClickCallback: () => void }) {
    const { watchListDispatcher } = useContext(WatchListContext);

    return (
        <RoundedIconTextButton
            theme={WatchListButtonTheme}
            icon={<WatchListIcon outline />}
            buttonText='Create Watchlist'
            tooltipText='Save all ETFs to a new Watchlist'
            onClickCallback={() => {
                onClickCallback();
                watchListDispatcher({ type: 'OpenSaveWatchlistModal' });
            }}
        />
    );
}

function CFRAInsightCard(props: { children: React.ReactNode }) {
    return (
        <ETFCard containerStyles={{ padding: '33px 20px 18px 20px', justifyContent: 'center' }}>
            {props.children}
        </ETFCard>
    );
}

export function CFRAInsight({
    insightDetails,
    thematicScreen,
}: {
    insightDetails: SingleThemeData;
    thematicScreen: string;
}) {
    if (!insightDetails) {
        throw new Error(`Can't find ${PageNames.CfraInsights} details.`);
    }

    const isBelowLg = useMediaQuery(breakpointsTheme.breakpoints.down('lg'));
    const isBelowMd = useMediaQuery(breakpointsTheme.breakpoints.down('md'));

    const screenerData = getScreenerData({
        thematicScreen: thematicScreen,
        view: '', // remove view='default'
    }) as UseQueryResult<ScreenerEtfData>;

    const gridRef = useRef<AgGridReact>(null);

    const [showUpdateWatchlist, setShowUpdateWatchlist] = useState<boolean>(false);
    const { watchListDispatcher } = useContext(WatchListContext);

    if (screenerData.isLoading) {
        return <ETFCard isLoading={screenerData.isLoading} />;
    }
    if (!screenerData.data) {
        throw new Error(`No screener data for ${insightDetails.display_name}`);
    }

    let { minWidths, customFlexibleColumns, columnDefs } = extractFromScreenerData({
        screenerData: screenerData.data,
        cardName: insightDetails.display_name,
    });
    minWidths['undefined'] = watchListColumnWidth; // avoid watch list column change size after columns auto sizing
    columnDefs.unshift({
        ...WatchListColumn,
        tooltipValueGetter: (params: ITooltipParams) =>
            params.api!.getDisplayedRowAtIndex(params.rowIndex!)!.isSelected()
                ? 'Remove from Watchlist'
                : 'create a watchlist',
        onCellClicked: (event: any) => {
            setShowUpdateWatchlist(true);
            watchListDispatcher({ type: 'OpenSaveWatchlistModal' });
            WatchListColumn.onCellClicked(event);
        },
    });

    columnDefs = columnDefs.map((colDef) => ({ ...colDef, suppressMovable: true }));

    const titleJsx = (
        <Box sx={{ width: '100%' }}>
            <Typography sx={LabelStyle}>{insightDetails.display_name}</Typography>
        </Box>
    );

    const descriptionJsx = (
        <Box sx={{ width: '100%' }}>
            <SharedDescription
                sx={{ ul: { marginBottom: 0 } }}
                dangerouslySetInnerHTML={{
                    __html: insightDetails.full_description,
                }}
            />
        </Box>
    );

    return (
        <AgGridSelectedRowsContextProvider isSSRMEnabled={false}>
            <ResultsContextProvider>
                <SaveScreenContextProvider>
                    <FiltersModalContextProvider>
                        <SnackbarProvider
                            classes={{
                                containerRoot: 'cfra-snackbar-root',
                            }}>
                            <WatchListModal
                                cardName='Watch List'
                                title='Create Watchlist'
                                createWatchlistPlaceholder='Name this watchlist'
                                showUpdateWatchlist={showUpdateWatchlist}
                                handleDuplicateIdsOnUpdate
                                externalViewWatchlist
                                onFinishCallback={() => {
                                    if (gridRef.current) {
                                        gridRef.current.api.deselectAll();
                                    }
                                }}
                                savedItemType={UserSavedItemsLookupID.Watchlist}
                            />
                        </SnackbarProvider>
                    </FiltersModalContextProvider>
                </SaveScreenContextProvider>
                <CFRAInsightCard>
                    <Box sx={{ maxWidth: '1620px' }}>
                        <Box
                            sx={{
                                width: '100%',
                                height: 'auto',
                                display: 'flex',
                                paddingBottom: '32px',
                                flexDirection: isBelowMd ? 'column' : 'row',
                            }}>
                            {isBelowMd ? (
                                <>
                                    {titleJsx}
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <Image
                                            src={insightDetails.image_url || ''}
                                            sx={{
                                                width: '50%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </Box>
                                    {descriptionJsx}
                                </>
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            flex: 0.6666,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'left',
                                            paddingRight: '36px',
                                        }}>
                                        {titleJsx}
                                        {descriptionJsx}
                                    </Box>
                                    <Box
                                        sx={{
                                            flex: 0.3333,
                                        }}>
                                        <Image
                                            src={insightDetails.image_url || ''}
                                            sx={{
                                                width: '100%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </Box>
                                </>
                            )}
                        </Box>
                        <Box sx={{ width: '100%', paddingBottom: '15px' }}>
                            <ETFsCount>{screenerData.data?.results.total} ETFs</ETFsCount>
                        </Box>
                        <Box sx={{ width: '100%', paddingBottom: '16px' }}>
                            <WatchListModalOpenButton
                                onClickCallback={() => {
                                    setShowUpdateWatchlist(false);
                                    if (gridRef.current) {
                                        gridRef.current.api.selectAll();
                                        gridRef.current.api.dispatchEvent({ type: 'selectionChanged' });
                                    }
                                }}
                            />
                        </Box>
                        <AgGrid.AgGrid
                            lastRowAdditionalWidth={10}
                            horizontalScrollbarAreaHeight={18}
                            ref={gridRef}
                            unlimitedCalculatedHeight
                            maxNumberOfRowsToDisplay={1000}
                            suppressRowClickSelection
                            columnDefs={columnDefs}
                            rowsData={screenerData.data.results.etf_data}
                            getResizableMinWidthForColumn={(headerName: string) =>
                                minWidths[headerName] || defaultMinWidth
                            }
                            updateCustomColumnsWidths={
                                customFlexibleColumns
                                    ? setCustomFlexWidths(isBelowLg, customFlexibleColumns)
                                    : undefined
                            }
                            gridTheme='small-grid-theme'
                            defaultRowHeight={92}
                            headerHeight={42}
                            rowsGap={20}
                        />
                    </Box>
                </CFRAInsightCard>
            </ResultsContextProvider>
        </AgGridSelectedRowsContextProvider>
    );
}
