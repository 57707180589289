import { AgGridCustomTooltip } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCustomTooltip';
import { plusIconTextButtonTheme } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { watchListColumnWidth } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants';
import { createTheme } from '@mui/material';
import { ITooltipParams } from 'ag-grid-community';

export const WatchListButtonTheme = createTheme(plusIconTextButtonTheme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    width: 'auto',
                },
            },
        },
    },
});

export const WatchListColumn = {
    filter: false,
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
    suppressHeaderFilterButton: true,
    headerClass: 'ag-grid-selection-column',
    sortable: false,
    cellClass: 'ag-grid-cfra-watchlist-icons',
    checkboxSelection: true,
    width: watchListColumnWidth,
    minWidth: watchListColumnWidth,
    tooltipValueGetter: (params: ITooltipParams) =>
        params.api!.getDisplayedRowAtIndex(params.rowIndex!)!.isSelected()
            ? 'Remove from Watchlist'
            : 'Add to Watchlist',
    tooltipComponent: AgGridCustomTooltip,
    tooltipComponentParams: { arrow: true, arrowPosition: { left: '4px', bottom: '100%' } },
    onCellClicked(event: any) {
        let node = event.node;

        let isSelected = node.isSelected() === undefined ? false : node.isSelected();
        node.setSelected(!isSelected);
    },
    pinned: 'left' as 'left',
};
